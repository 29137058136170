import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "companyFields", "companyList", "placesList", "lat", "long", "address", "workType", "payInterval", "waterOption", "categoriesList", "categoryInput"]
  
  connect() {
  
  }
  
  showCompanyForm(e) {
    e.preventDefault()
    this.companyFieldsTarget.classList.remove("hidden")
    this.companyListTarget.classList.add("hidden")
  }
  
  setCoords(e) {
    e.preventDefault()
    this.placesListTarget.innerHTML = ""
    console.log(e.target.innerHTML)
    console.log(e.target.dataset.lat)
    console.log(e.target.dataset.long)
    this.latTarget.value = e.target.dataset.lat
    this.longTarget.value = e.target.dataset.long
    this.addressTarget.value = e.target.innerHTML
  }
  
  changeWorkType(e) {
    for (let i = 0; i < this.workTypeTargets.length; i++) {
      this.workTypeTargets[i].classList.remove('bg-blue-600', 'text-white')
    }
    e.target.classList.add('bg-blue-600', 'text-white')
  }
  
  changePayInterval(e) {
    for (let i = 0; i < this.payIntervalTargets.length; i++) {
      this.payIntervalTargets[i].classList.remove('bg-blue-600', 'text-white')
    }
    e.target.classList.add('bg-blue-600', 'text-white')
  }
  
  changeWaterOption(e) {
    for (let i = 0; i < this.waterOptionTargets.length; i++) {
      this.waterOptionTargets[i].classList.remove('bg-blue-600', 'text-white')
    }
    e.target.classList.add('bg-blue-600', 'text-white')
  }
  
  addCategory(e) {
    console.log(e.target.dataset)
    this.categoriesListTarget.innerHTML = ""
    this.categoryInputTarget.value = ""
    
    document.getElementById(e.target.dataset.id).checked = true
  }
  
  searchCats(e) {
    let list = this.categoriesListTarget
    
    fetch('/api/categories?search=' + e.target.value)
    .then(function(response) {
      list.innerHTML = ""
      return response.json();
    })
    .then(function(categories) {
      console.log(categories);
      for (let i = 0; i < categories.length; i++) {
        let list_item = document.createElement("li")
        let link = document.createElement("a")
        link.innerHTML = categories[i]["name"]
        link.classList.add("py-2", "px-4", "block", "text-blue-700", "cursor-pointer")
        link.dataset.action = 'click->listings#addCategory'
        link.dataset.id = categories[i]["id"]
        link.dataset.name = categories[i]["name"]
        list_item.appendChild(link)
        list.append(list_item)
      }
    });
  }
  
  checkAddress(e) {
    let list = this.placesListTarget
    
    fetch('https://api.mapbox.com/geocoding/v5/mapbox.places/'+ e.target.value +'.json?proximity=ip&types=place%2Cpostcode%2Caddress&access_token=pk.eyJ1IjoiYWlydHdvIiwiYSI6ImNsNjE5MHRwMjAyczUza3FrNWtrY2dncmcifQ.gLsOs2vm13ZoZ9jUdzvT-A')
    .then(function(response) {
      list.innerHTML = ""
      return response.json();
    })
    .then(function(myJson) {
      console.log(myJson)
    
      const places = myJson.features.map(f => ({name: f['place_name'], lat: f['geometry']['coordinates'][1], long: f['geometry']['coordinates'][0]}))
      
      console.log(places)
      for (let i = 0; i < places.length; i++) {
        let list_item = document.createElement("li")
        let link = document.createElement("a")
        link.innerHTML = places[i]["name"]
        link.classList.add("py-2", "px-4", "block", "text-blue-700", "cursor-pointer")
        link.dataset.action = 'click->listings#setCoords'
        link.dataset.lat = places[i]["lat"]
        link.dataset.long = places[i]["long"]
        list_item.appendChild(link)
        list.append(list_item)
      }

    });
  }
}
